// scss
import './../scss/_main.scss';
// js
import './../js/slide_menu.js';
import './../js/banner_slider.js';
import './../js/gallery.js';




